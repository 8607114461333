.list {
  padding-bottom: 7px;
}
.aLink {
  padding: 0.5rem;
  color: white;
  text-decoration: none;
}

.aLink:hover {
  border-bottom: 1px dotted white;
}