.footer {
    background-color: #006eff;
    min-height: 60vh;
    position: absolute;
    width: 100%;
}

.footerlogo{
    width: auto;
    height: 100px;
}